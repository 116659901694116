import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';

var getFmtString = function getFmtString(item) {
  return item.name + JSON.stringify(item.query) + JSON.stringify(item.params);
};

export default {
  name: 'HistoryComponent',
  data: function data() {
    return {
      historys: [],
      activeValue: '',
      contextMenuVisible: false,
      left: 0,
      top: 0,
      isCollapse: false,
      isMobile: false,
      rightActive: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo', 'activeColor'])), {}, {
    defaultRouter: function defaultRouter() {
      return this.userInfo.authority.defaultRouter;
    }
  }),
  watch: {
    contextMenuVisible: function contextMenuVisible() {
      var _this = this;

      if (this.contextMenuVisible) {
        document.body.addEventListener('click', function () {
          _this.contextMenuVisible = false;
        });
      } else {
        document.body.removeEventListener('click', function () {
          _this.contextMenuVisible = false;
        });
      }
    },
    $route: function $route(to, now) {
      this.historys = this.historys.filter(function (item) {
        return !item.meta.closeTab;
      });
      this.setTab(to);
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
      this.activeValue = window.sessionStorage.getItem('activeValue');

      if (now && to && now.name === to.name) {
        this.$bus.$emit('reload');
      }
    }
  },
  created: function created() {
    var _this2 = this;

    this.$bus.on('mobile', function (isMobile) {
      _this2.isMobile = isMobile;
    });
    this.$bus.on('collapse', function (isCollapse) {
      _this2.isCollapse = isCollapse;
    });
    var initHistorys = [{
      name: this.defaultRouter,
      meta: {
        title: '首页'
      },
      query: {},
      params: {}
    }];
    this.historys = JSON.parse(sessionStorage.getItem('historys')) || initHistorys;

    if (!window.sessionStorage.getItem('activeValue')) {
      this.activeValue = getFmtString(this.$route);
    } else {
      this.activeValue = window.sessionStorage.getItem('activeValue');
    }

    this.setTab(this.$route);
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off('collapse');
    this.$bus.off('mobile');
  },
  methods: {
    name: function name(item) {
      return item.name + JSON.stringify(item.query) + JSON.stringify(item.params);
    },
    openContextMenu: function openContextMenu(e) {
      if (this.historys.length === 1 && this.$route.name === this.defaultRouter) {
        return false;
      }

      var id = '';

      if (e.srcElement.nodeName === 'SPAN') {
        console.log(e);
        id = e.srcElement.offsetParent.id;
      } else {
        id = e.srcElement.id;
      }

      if (id) {
        this.contextMenuVisible = true;
        var width;

        if (this.isCollapse) {
          width = 54;
        } else {
          width = 220;
        }

        if (this.isMobile) {
          width = 0;
        }

        this.left = e.clientX - width;
        this.top = e.clientY + 10;
        this.rightActive = id.split('-')[1];
      }
    },
    closeAll: function closeAll() {
      this.historys = [{
        name: this.defaultRouter,
        meta: {
          title: '首页'
        },
        query: {},
        params: {}
      }];
      this.$router.push({
        name: this.defaultRouter
      });
      this.contextMenuVisible = false;
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeLeft: function closeLeft() {
      var _this3 = this;

      var right;
      var rightIndex = this.historys.findIndex(function (item) {
        if (getFmtString(item) === _this3.rightActive) {
          right = item;
        }

        return getFmtString(item) === _this3.rightActive;
      });
      var activeIndex = this.historys.findIndex(function (item) {
        return getFmtString(item) === _this3.activeValue;
      });
      this.historys.splice(0, rightIndex);

      if (rightIndex > activeIndex) {
        this.$router.push(right);
      }

      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeRight: function closeRight() {
      var _this4 = this;

      var right;
      var leftIndex = this.historys.findIndex(function (item) {
        if (getFmtString(item) === _this4.rightActive) {
          right = item;
        }

        return getFmtString(item) === _this4.rightActive;
      });
      var activeIndex = this.historys.findIndex(function (item) {
        return getFmtString(item) === _this4.activeValue;
      });
      this.historys.splice(leftIndex + 1, this.historys.length);

      if (leftIndex < activeIndex) {
        this.$router.push(right);
      }

      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeOther: function closeOther() {
      var _this5 = this;

      var right;
      this.historys = this.historys.filter(function (item) {
        if (getFmtString(item) === _this5.rightActive) {
          right = item;
        }

        return getFmtString(item) === _this5.rightActive;
      });
      this.$router.push(right);
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    isSame: function isSame(route1, route2) {
      if (route1.name !== route2.name) {
        return false;
      }

      for (var key in route1.query) {
        if (route1.query[key] !== route2.query[key]) {
          return false;
        }
      }

      for (var _key in route1.params) {
        if (route1.params[_key] !== route2.params[_key]) {
          return false;
        }
      }

      return true;
    },
    setTab: function setTab(route) {
      var _this6 = this;

      if (!this.historys.some(function (item) {
        return _this6.isSame(item, route);
      })) {
        var obj = {};
        obj.name = route.name;
        obj.meta = route.meta;
        obj.query = route.query;
        obj.params = route.params;
        this.historys.push(obj);
      }

      window.sessionStorage.setItem('activeValue', getFmtString(this.$route));
    },
    changeTab: function changeTab(component) {
      var tab = component.$attrs.tab;
      this.$router.push({
        name: tab.name,
        query: tab.query,
        params: tab.params
      });
    },
    removeTab: function removeTab(tab) {
      var index = this.historys.findIndex(function (item) {
        return getFmtString(item) === tab;
      });

      if (getFmtString(this.$route) === tab) {
        if (this.historys.length === 1) {
          this.$router.push({
            name: this.defaultRouter
          });
        } else {
          if (index < this.historys.length - 1) {
            this.$router.push({
              name: this.historys[index + 1].name,
              query: this.historys[index + 1].query,
              params: this.historys[index + 1].params
            });
          } else {
            this.$router.push({
              name: this.historys[index - 1].name,
              query: this.historys[index - 1].query,
              params: this.historys[index - 1].params
            });
          }
        }
      }

      this.historys.splice(index, 1);
    }
  }
};